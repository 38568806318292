import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`What I’m doing now`}</h1>
    <p>{`(last updated: 02/01/2022)`}</p>
    <p>{`Working on creating more content, this garden and my socials will be 10x better by end of this year (hopefully).`}</p>
    <h2>{`Projects I'm working on`}</h2>
    <ul>
      <li parentName="ul">{`MORE CONTENT`}</li>
      <li parentName="ul">{`Genarative Art`}</li>
    </ul>
    <h2>{`Books I'm reading`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amazon.in/Great-Mental-Models-Systems-Mathematics-ebook/dp/B09G3K5W2R/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1641089494&sr=8-2"
        }}>{`The Great Mental Models: Systems and Mathematics`}</a></li>
    </ul>
    <p>{`This “Now” page is inspired by
`}<a parentName="p" {...{
        "href": "https://nownownow.com/about"
      }}>{`Derek Sivers nownownow project`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      